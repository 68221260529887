import React from "react";
import MapButton from "./MapButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const EventDetailsBanner = ({ data, targetId }) => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleViewMap = () => {
    window.open(data.location, "_blank");
  };

  // Function to log button clicks to the backend
  const logButtonClick = async (buttonType) => {
    try {
      // Adjust the URL to point to the correct server location
      await axios.post(`${apiUrl}/log-button-click`, {
        eventName: data.heading, // or use data.id for event ID
        buttonType: buttonType,  // e.g., "Book Now", "Other", "View Details"
      });
    } catch (error) {
      console.error("Error logging button click:", error);
    }
  };

  const handleRedirect = async (url, buttonType) => {
    // Log the button click to the backend
    await logButtonClick(buttonType);

    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  const handleViewDetails = async () => {
    const section = document.querySelector("#event-details-main");
    section.scrollIntoView({ behavior: "smooth", block: "start" });

    // Log "View Details" button click to the backend
    await logButtonClick("view_details");
  };

  const isYouTubeLink = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  return (
    <div className="event-details-banner-container">
      <div
        className="event-details-banner"
        style={{ backgroundImage: `url(${data.backgroundImage})` }}
      >
        {data.backgroundVideo ? (
          isYouTubeLink(data.backgroundVideo) ? (
            <iframe
              className="background-video"
              src={data.backgroundVideo}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          ) : (
            <video
              className="background-video"
              autoPlay
              loop
              muted
              playsInline
              src={data.backgroundVideo}
            />
          )
        ) : null}

        <div className="left-side">
          <h1 className="heading">{data.heading}</h1>
          <h2 className="subheading">{data.city}</h2>
          <MapButton
            data={{
              text: "View Map",
              onClick: handleViewMap,
            }}
          />
        </div>

        <div className="event-image">
          {data.backgroundVideo ? (
            isYouTubeLink(data.backgroundVideo) ? (
              <iframe
                src={data.backgroundVideo}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            ) : (
              <video autoPlay loop muted playsInline src={data.backgroundVideo} />
            )
          ) : (
            <img src={data.backgroundImage} alt="Event" />
          )}
        </div>

        {data.buttonContent1 && (
          <div className="right-side">
            <div className="nested-banner">
              <h3 className="date-time-heading">Date and Time</h3>
              <p className="details">{`${data.date}, ${data.time}`}</p>
              <button
                className="book-now-btn"
                onClick={() => handleRedirect(data.buttonLink1, "Book Now")}
              >
                {data.buttonContent1}
              </button>
              {data.buttonContent2 ? (
                <button
                  className="book-now-btn"
                  onClick={() => handleRedirect(data.buttonLink2, "Other")}
                >
                  {data.buttonContent2}
                </button>
              ) : (
                <a className="view-details-btn" onClick={handleViewDetails}>
                  View Details
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetailsBanner;
