import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useEffect } from "react";
import axios from "axios";

const AccountPage = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [activeTab, setActiveTab] = useState("account");
  const iconPath = process.env.PUBLIC_URL + "/icons/";
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.post(`${apiUrl}/verify-token`, {
            token,
          });
          if (response.status === 200) {
            setUserDetails(response.data.userDetails);
            console.log(response.data.userDetails);
          } else {
            console.log("Failed to verify token");
            setUserDetails(undefined);
          }
        } catch (error) {
          console.log("Error verifying token:", error);
          setUserDetails(undefined);
        }
      } else {
        setUserDetails(undefined);
      }
    };
    fetchUserDetails();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "account":
        return <div className="account-container"><h6>this is the account content</h6></div>;
      case "payment":
        return <div className="payment-container"><h6>this is the payment content</h6></div>;
      default:
        return <div>select an option from the sidebar</div>;
    }
  };

  const getFormattedDate = () => {
    const today = new Date();

    const options = {
      weekday: "short",
      year: "numeric",
      month: "long",
      day: "2-digit",
    };
    const formattedDate = today.toLocaleDateString("en-GB", options);

    return formattedDate.replace(",", ",");
  };

  return (
    <>
      <Header searchBar={true} />
      <div className="main-body account">
        <div className="account-page-container">
          <div className="vertical-sidebar">
            <button
              className={`icon-button ${
                activeTab === "account" ? "active" : ""
              }`}
              onClick={() => setActiveTab("account")}
            >
              <img
                className="navigation-icon"
                src={
                  iconPath +
                  (activeTab === "account"
                    ? "profile-active.svg"
                    : "profile-inactive.svg")
                }
                alt="account-icon"
              />
            </button>
            <button
              className={`icon-button ${
                activeTab === "payment" ? "active" : ""
              }`}
              onClick={() => setActiveTab("payment")}
            >
              <img
                className="navigation-icon"
                src={
                  iconPath +
                  (activeTab === "payment"
                    ? "ticket-active.svg"
                    : "ticket-inactive.svg")
                }
                alt="ticket-icon"
              />
            </button>
          </div>
          <div className="main-content">
            <div className="greeting">
              {userDetails === null || userDetails === undefined ? (
                <h6 className="heading">Welcome, Guest</h6>
              ) : (
                <h6 className="heading">Welcome, {userDetails.first_name}</h6>
              )}
              <h6 className="current-date">{getFormattedDate()}</h6>
            </div>
            {renderContent()}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccountPage;
