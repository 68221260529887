import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import SignupPage from './pages/SignupPage';
import LoginPage from './pages/LoginPage';
import ForgotPage from './pages/ForgotPage';
import ResetPage from './pages/ResetPage';
import ResetSuccessPage from './pages/ResetSuccessPage';
import LoaderPage from './pages/LoaderPage';
import VerificationPage from './pages/VerificationPage';
import VerifySuccessPage from './pages/VerifySuccessPage';
import Home from './pages/Home';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import Discover from './pages/Discover';
import EventDetails from './pages/EventDetails';
import EventPage from './pages/EventPage';
import { initGA, logPageView } from './utils/analytics';
import ProtectedRoute from './components/ProtectedRoute';
import AccountPage from './pages/AccountPage';

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics when the app loads
    initGA(); // Ensure this is called before any page view is logged
  }, []);

  return (
    <Router>
      <RoutesWrapper />
    </Router>
  );
};

const RoutesWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    // Ensure GA is initialized before logging page views
    initGA().then(() => {
      logPageView(); // Log the page view after GA is initialized
    });
  }, [location]);

  return (
    <Routes>
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot" element={<ForgotPage />} />
      <Route path="/reset" element={<ResetPage />} />
      <Route path="/reset1" element={<ResetSuccessPage />} />
      <Route path="/loader" element={<LoaderPage />} />
      <Route path="/verify" element={<VerificationPage />} />
      <Route path="/verify1" element={<VerifySuccessPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms-cond" element={<PrivacyPage />} />

      <Route path="/home" element={<Home />} />
      <Route path="/account" element={<AccountPage />} />
      
      {/* Discover route with and without categoryID */}
      <Route path="/discover" element={<Discover />} />
      <Route path="/discover/:categoryID" element={<Discover />} />

      <Route
        path="/add-event"
        element={
          <ProtectedRoute>
            <EventPage />
          </ProtectedRoute>
        }
      />
      <Route path="/event-details/:eventName" element={<EventDetails />} />
      <Route path="/" element={<Navigate replace to="/home" />} />
    </Routes>
  );
};

export default App;
