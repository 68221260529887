import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { useFilter } from "../context/FilterContext";
import { Switch, FormControlLabel, FormGroup, IconButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const Sidebar = ({
  minPrice,
  maxPrice,
  locationFilterOptions,
  categoryFilterOptions,
}) => {
  const { filterDispatch, type, loc, cat, price, sort } = useFilter();
  const handleClearClick = () => {
    filterDispatch({ type: "CLEAR" });
  };

  const handleTypeChange = (e, option) => {
    const check = e.target.checked;
    filterDispatch({ type: "TYPE", payload: { option, check } });
  };

  const handleLocationChange = (e, option) => {
    const check = e.target.checked;
    filterDispatch({ type: "LOCATION", payload: { option, check } });
  };

  const handleCategoryChange = (e, option) => {
    const check = e.target.checked;
    filterDispatch({ type: "CATEGORY", payload: { option, check } });
  };

  const handlePriceChange = (e, option) => {
    filterDispatch({ type: "PRICE", payload: { option } });
  };

  const handlePriceSorting = (e, option) => {
    filterDispatch({
      type: option.toUpperCase(),
      payload: option.toUpperCase(),
    });
  };

  const locationFilters = [
    "Islamabad",
    "Rawalpindi",
    "Lahore",
    "Muzaffarabad"
    // "Karachi",
    // "Quetta",
    // "Peshawar",
  ];

  const [openSections, setOpenSections] = useState({
    sort: true,
    type: true,
    locations: true,
    categories: true,
    price: true,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  useEffect(() => {
    // Initialize with default values or values you want to set
    const initialPriceRange = [minPrice, maxPrice];
    handlePriceChange(null, initialPriceRange); // Pass initial values
  }, [minPrice, maxPrice]);
  return (
    <div className="sidebar">
      <div className="flex center justify-between">
        <h2 className="heading">Filter Options</h2>
        <hr className="horizontal-line" />
        <button className="clear-button" onClick={handleClearClick}>
          Clear
        </button>
      </div>
      <div className="section">
        <div className="section-header" onClick={() => toggleSection("sort")}>
          <h2 className="heading">Sort by Price</h2>
          <IconButton>
            {openSections.sort ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>

        {openSections.sort && (
          <div className="section-content">
            <label className="item">
              <input
                type="radio"
                name="price"
                value="lth"
                onChange={(e) => handlePriceSorting(e, "lth")}
                checked={sort.toLowerCase() === "lth"}
              />
              <h6>Low to High</h6>
            </label>
            <label className="item">
              <input
                type="radio"
                name="price"
                value="htl"
                onChange={(e) => handlePriceSorting(e, "htl")}
                checked={sort.toLowerCase() === "htl"}
              />
              <h6>High to Low</h6>
            </label>
          </div>
        )}
        <hr className="horizontal-line" />
      </div>

      <div className="section">
        <div className="section-header" onClick={() => toggleSection("type")}>
          <h2 className="heading">Type</h2>
          <IconButton>
            {openSections.type ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>

        {openSections.type && (
          <div className="section-content">
            <FormGroup className="switches">
              <FormControlLabel
                control={
                  <Switch
                    checked={type.includes("Online")}
                    onChange={(e) => handleTypeChange(e, "Online")}
                    name="type"
                    value="Online"
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#0788ff",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        { backgroundColor: "#0788ff" },
                    }}
                  />
                }
                label="Online"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={type.includes("Physical")}
                    onChange={(e) => handleTypeChange(e, "Physical")}
                    name="type"
                    value="Physical"
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#0788ff",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        { backgroundColor: "#0788ff" },
                    }}
                  />
                }
                label="Physical"
              />
            </FormGroup>
          </div>
        )}
        <hr className="horizontal-line" />
      </div>

      <div className="section">
        <div
          className="section-header"
          onClick={() => toggleSection("locations")}
        >
          <h2 className="heading">Locations</h2>
          <IconButton>
            {openSections.locations ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>

        {openSections.locations && (
          <div className="section-content">
            {locationFilters.map((location) => (
              <label
                key={location}
                className={
                  loc && loc.includes(location) ? "item item-active" : "item"
                }
              >
              <input
                type="checkbox"
                name="location"
                value={location}
                checked={
                  (location === "Islamabad" || location === "Rawalpindi" || location === "Lahore")
                    ? loc && loc.includes(location)
                    : false
                }
                onChange={(e) => handleLocationChange(e, location)}
                disabled={location !== "Islamabad" && location !== "Rawalpindi" && location !== "Lahore"}
              />
              <h6
                className={
                  (location !== "Islamabad" && location !== "Rawalpindi"  && location !== "Lahore"&& location !== "Muzaffarabad") && !loc.includes(location)
                    ? "item-grey"
                    : ""
                }
              >
                {location}
              </h6>

              </label>
            ))}
          </div>
        )}
        <hr className="horizontal-line" />
      </div>

      <div className="section">
        <div
          className="section-header"
          onClick={() => toggleSection("categories")}
        >
          <h2 className="heading">Categories</h2>
          <IconButton>
            {openSections.categories ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>

        {openSections.categories && (
          <div className="section-content">
            {categoryFilterOptions.map((category) => (
              <label
                className={
                  cat && cat.includes(category) ? "item item-active" : "item"
                }
                key={category}
              >
                <input
                  type="checkbox"
                  value={category}
                  checked={cat && cat.includes(category)}
                  onChange={(e) => handleCategoryChange(e, category)}
                />
                <h6>{category}</h6>
              </label>
            ))}
          </div>
        )}
        <hr className="horizontal-line" />
      </div>

      <div className="section">
        <div className="section-header" onClick={() => toggleSection("price")}>
          <h2 className="heading">Price Range</h2>
          <IconButton>
            {openSections.price ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>

        {openSections.price && (
          <div className="section-content">
            <Slider
              className="slider"
              value={price}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={minPrice}
              max={maxPrice}
              size="small"
            />
            <div
              className="price-input"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label htmlFor="minPrice">Min Price:</label>
                <input
                  type="number"
                  id="minPrice"
                  value={price[0]}
                  onChange={(e) =>
                    handlePriceChange(e, [+e.target.value, price[1]])
                  }
                />
              </div>
              <div>
                <label htmlFor="maxPrice">Max Price:</label>
                <input
                  type="number"
                  id="maxPrice"
                  value={price[1]}
                  onChange={(e) =>
                    handlePriceChange(e, [price[0], +e.target.value])
                  }
                />
              </div>
            </div>
          </div>
        )}
        {/* <hr className="horizontal-line" /> */}
      </div>
    </div>
  );
};

export default Sidebar;
